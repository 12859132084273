<template>
    <div>
      <van-field
        readonly
        clickable
        type="textarea"
        rows="1"
        autosize
        :value="displayValue"
        :placeholder="placeholder"
        @click="showPicker = true"
      />
  
      <van-popup v-model="showPicker" position="bottom" v-if="!readonly">
        <van-search v-model="searchQuery" :placeholder="$t('common.search')" />
        <van-picker
          :confirm-button-text="$t('common.confirm')"
          :cancel-button-text="$t('common.cancel')"
          :default-index="defaultIndex"
          show-toolbar
          :columns="formattedColumns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
    </div>
  </template>
  
  <script>
  import { Field,Popup, Picker,Search } from "vant";

  export default {
    components: {
        [Popup.name]: Popup,
        [Picker.name]: Picker,
        [Search.name]: Search,
        [Field.name]: Field,
    },
    name: "SearchAddressView",
    props: {
      readonly: {
        type: Boolean,
        default: false
      },
      defaultIndex: {
        type: Number,
        default: 0
      },
      value: {
        type: [Number, String, Array],
        default: () => [],
      },
      columns: {
        type: Array,
        required: true,
      },
      placeholder: {
        type: String,
        default: "点击选择",
      },
      valueKey: {
        type: String,
        default: "value", // 默认使用 'value' 作为选项的值
      },
      labelKey: {
        type: String,
        default: "name", // 默认使用 'name' 作为选项的标签
      },
    },
    data() {
      return {
        searchQuery: "",
        showPicker: false,
        selectedValues: Array.isArray(this.value)
          ? [...this.value]
          : [this.value],
      };
    },
    computed: {
      displayValue() {
        const selectedTexts = this.columns
          .filter((item) =>
            this.multiSelect
              ? this.selectedValues.includes(item[this.valueKey])
              : item[this.valueKey] === this.value
          )
          .map((item) => item[this.labelKey]);
        return selectedTexts.join(", ");
      },
      formattedColumns() {
        let arr = [];
        if (this.searchQuery) {
          arr = this.columns.filter((option) => {
            return option[this.labelKey].toUpperCase().includes(this.searchQuery.toUpperCase());
          });
        } else {
          arr = this.columns;
        }
  
        return arr.map((item) => ({
          text: item[this.labelKey],
          value: item[this.valueKey],
        }));
      },
    },
    watch: {
      value: {
        immediate: true,
        handler(newValue, oldValue) {
          if(newValue?.length === oldValue?.length) return
          this.selectedValues = Array.isArray(newValue) ? [...newValue] : [newValue];
        }
      },
      selectedValues(val) {
        if (this.multiSelect) {
          this.$emit("input", val);
        }
      },
    },
    methods: {
      onConfirm(selected) {
        if (this.multiSelect) {
          const valueIndex = this.selectedValues.indexOf(selected.value);
          if (valueIndex === -1) {
            this.selectedValues.push(selected.value);
          } else {
            this.selectedValues.splice(valueIndex, 1);
          }
          this.$emit("input", [...this.selectedValues]);
        } else {
          this.$emit("input", selected.value);
          this.showPicker = false;
        }
      },
      confirmSelection() {
        this.$emit("input", [...this.selectedValues]);
        this.showPicker = false;
      },
    },
  };
  </script>
  
  <style scoped>
  </style>
  
  